body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  word-wrap: break-word;
  background-color: #001529;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  color: white;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.m0qfmi528hn-editor_css {
  font-size: 24px;
  color: #fbfbfb;
}
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: #fff;
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    color: #fff;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
    color: #fff;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header {
  background: #001529d5;
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  top: 0;
}
.header .home-page {
  padding: 0 24px;
}
.header-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header-logo a {
  display: block;
}
.header-menu {
  float: right;
}
.header-menu > .menu-item {
  line-height: 62px;
  display: inline-block;
  padding: 0 20px;
  cursor: pointer;
  background: transparent;
  color: #fff;
  height: 64px;
  border-bottom-color: transparent;
  position: relative;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.header-menu > .menu-item.active,
.header-menu > .menu-item:hover {
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}
@media screen and (max-width: 767px) {
  .header-logo {
    z-index: 101;
  }
  .header.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header-menu .menu-item {
    display: block;
    width: 100%;
    padding: 0 24px;
    border-bottom: none;
    line-height: 40px;
    height: 40px;
    margin: 4px 0 8px;
  }
  .header-menu .menu-item.active,
  .header-menu .menu-item:hover {
    border: none;
    background: #1890ff;
    color: #fff;
  }
  .header-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header-mobile-menu :first-child {
    margin-top: 0;
  }
  .header .open {
    height: auto;
  }
  .header .open .header-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header .open .header-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header .open .header-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header .open .header-menu {
    opacity: 1;
    pointer-events: auto;
  }
}
.Banner {
  width: 100%;
  height: 100vh;
  position: relative;
  border-color: #666;
  background: #fff;
}
.Banner-wrapper,
.Banner .banner-anim {
  height: 100%;
}
.Banner .queue-anim-leaving {
  position: relative !important;
}
.Banner .banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.Banner .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
}
.Banner .banner-user-elem .banner-user-title {
  font-size: 22px;
  top: 40%;
}
.Banner .banner-user-elem .banner-user-text {
  top: 40%;
}
.Banner .Banner-text-wrapper {
  display: block;
  position: relative;
  top: 20%;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 14px;
  color: #fff;
  width: 650px;
  text-align: center;
  padding: 15px 50px;
}
.Banner .Banner-title {
  width: 350px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.Banner .Banner-content {
  margin-bottom: 20px;
  word-wrap: break-word;
}
.Banner .Banner-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  line-height: 36px;
  font-size: 16px;
  height: 36px;
}
.Banner .Banner-button span {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: text-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.Banner .Banner-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.Banner .Banner-button:hover span {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}
.Banner .Banner-button.queue-anim-leaving {
  width: auto;
}
.Banner .Banner-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  z-index: 10;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
.banner-anim-arrow-default {
  width: 3rem;
  height: 7rem;
  border-radius: 0.5rem;
}
.banner-anim-arrow-default.prev:before {
  transform: rotate(40deg);
  top: 43px;
  left: 18px;
}
.banner-anim-arrow-default.prev:after {
  transform: rotate(-40deg);
  bottom: 43px;
  left: 18px;
}
.banner-anim-arrow-default.next:before {
  transform: rotate(-40deg);
  top: 43px;
  left: 25px;
}
.banner-anim-arrow-default.next:after {
  transform: rotate(40deg);
  bottom: 43px;
  left: 25px;
}
.banner-anim-thumb-default span {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 767px) {
  .Banner .Banner-text-wrapper {
    width: 90%;
  }
  .Banner .Banner-text-wrapper .Banner-title {
    width: 90%;
    left: 0;
  }
  .Banner .bg {
    background-attachment: inherit;
  }
  .banner-anim-arrow-default {
    width: 20px;
    height: 60px;
    border-radius: 3px;
  }
  .banner-anim-arrow-default.prev:before {
    top: 18px;
    left: 8px;
  }
  .banner-anim-arrow-default.prev:after {
    bottom: 17px;
    left: 8px;
  }
  .banner-anim-arrow-default.next:before {
    top: 18px;
    left: 10px;
  }
  .banner-anim-arrow-default.next:after {
    bottom: 17px;
    left: 10px;
  }
}
.business {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #fdfdfd;
  background-image: linear-gradient(to top, rgba(253, 253, 253, 0.3) 0%, rgba(193, 218, 255, 0.3) 100%);
  overflow: hidden;
}
.business .business-page {
  padding: 1px 0 0;
  max-width: 75%;
}
.business-title {
  font-size: 64px;
  line-height: 1.5;
  color: #ffffff;
  margin-bottom: 8px;
}
.business-title-wrapper {
  width: 55%;
  max-width: 850px;
  padding: 72px;
  position: relative;
  top: 30%;
  left: 24px;
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.business-explain {
  color: #ffffff;
  font-size: 32px;
  margin-bottom: 24px;
}
.business-content {
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 48px;
}
.business-button-wrapper .business-button {
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
  box-shadow: 0 8px 24px rgba(95, 155, 241, 0.35);
  border-radius: 20px;
  border: none;
  padding: 0 42px;
  line-height: 40px;
  height: 40px;
  transition: transform 0.3s, box-shadow 0.3s;
}
.business-button-wrapper .business-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 13px 24px rgba(95, 155, 241, 0.45);
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
}
.business-image {
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .business {
    min-height: 500px;
  }
  .business .business-page.home-page {
    padding-top: 1px;
    padding-bottom: 0;
  }
  .business-title-wrapper {
    text-align: center;
    position: static;
    width: 100%;
    margin-top: 12rem;
    left: 0;
    top: 0;
  }
  .business-title {
    font-size: 32px;
  }
  .business-explain {
    font-size: 18px;
  }
  .business-content {
    font-size: 14px;
  }
  .business-image {
    position: static;
    width: 100%;
    top: 0;
    right: 0;
    margin: 32px 0 24px;
  }
}
.Infomation-wrapper {
  min-height: 764px;
}
.Infomation-wrapper .Infomation {
  height: 100%;
  overflow: hidden;
}
.Infomation-wrapper .Infomation .title-content {
  text-align: center;
}
.Infomation-wrapper .Infomation .title-h1 {
  color: #fff;
  text-shadow: 2px 3px 5px #0000009d;
}
.Infomation-wrapper .Infomation-block-wrapper {
  position: relative;
}
.Infomation-wrapper .Infomation-block-wrapper .Infomation-block-img {
  display: inline-block;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: -1;
  border-radius: 5px;
  vertical-align: top;
}
.Infomation-wrapper .Infomation-block-wrapper .Infomation-block-img-n {
  display: inline-block;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: -1;
  border-radius: 5px;
  background-color: #daecfc94;
  vertical-align: top;
}
.Infomation-wrapper .Infomation-block-wrapper .Infomation-block {
  display: inline-block;
  padding: 24px 24px;
  border: 1px solid #ffffff;
  border-radius: 5px;
  vertical-align: top;
}
.Infomation-wrapper .Infomation-block-wrapper .Infomation-block .Infomation-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.Infomation-wrapper .Infomation-block-wrapper .Infomation-block .Infomation-title {
  color: #000;
  font-weight: bolder;
}
.Infomation-wrapper .Infomation-block-wrapper .Infomation-block .Infomation-title-a {
  color: #000;
}
.Infomation-wrapper .Infomation-block-wrapper .Infomation-block .Infomation-title-a:hover {
  color: #133664;
}
.Infomation-wrapper .Infomation-block-wrapper .Infomation-block .Infomation-text {
  width: 100%;
  display: inline-block;
  padding: 8%;
  border-radius: 5px;
  color: #000;
  background-color: #daecfc94;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  min-height: 110px;
  max-height: 110px;
}
.Infomation-wrapper .Infomation-block-wrapper .Infomation-block.clear-both {
  clear: both;
}
.Infomation-wrapper .Infomation-block-first-img {
  display: inline-block;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: -1;
  border-radius: 3px;
  vertical-align: top;
}
.Infomation-wrapper .Infomation-block-first-img-n {
  display: inline-block;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-color: #daecfc94;
  border-radius: 3px;
  vertical-align: top;
}
.Infomation-wrapper .Infomation-block-first {
  display: inline-block;
  padding: 36px;
  height: 100%;
  border: 3px solid #ffffff;
  border-radius: 5px;
  vertical-align: top;
}
.Infomation-wrapper .Infomation-block-first .Infomation-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.Infomation-wrapper .Infomation-block-first .Infomation-title {
  color: #000;
  font-weight: bolder;
}
.Infomation-wrapper .Infomation-block-first .Infomation-title-a {
  color: #000;
}
.Infomation-wrapper .Infomation-block-first .Infomation-title-a:hover {
  color: #133664;
}
.Infomation-wrapper .Infomation-block-first .Infomation-text {
  width: 100%;
  display: inline-block;
  padding: 8%;
  border-radius: 5px;
  color: #000;
  background-color: #daecfc94;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  min-height: 242px;
  max-height: 242px;
}
.Infomation-wrapper .Infomation-block-first.clear-both {
  clear: both;
}
@media screen and (max-width: 767px) {
  .Infomation-wrapper {
    min-height: 1080px;
  }
  .Infomation-wrapper .Infomation-block-wrapper {
    margin: 20px auto;
    height: auto;
  }
  .Infomation-wrapper .Infomation-block-wrapper .Infomation-block .Infomation-title {
    font-size: 20px;
  }
  .Infomation-wrapper .Infomation-block-wrapper .Infomation-block.queue-anim-leaving {
    position: relative !important;
  }
}
.Partners-logo {
  width: 220px;
}
.Partners-wrapper {
  min-height: 564px;
  margin: 0 auto;
  overflow: hidden;
  background-color: #f7f9fc;
}
.Partners-wrapper.home-page-wrapper .home-page {
  padding: 100px 24px;
}
.Partners-title-wrapper {
  text-align: center;
  margin-bottom: 40px;
}
.Partners-title-h1 {
  font-size: 32px;
  color: #ffffff;
  text-shadow: 2px 3px 5px #0000009d;
}
.Partners-title-content {
  margin-top: 16px;
}
.Partners-block {
  margin-top: 28px;
  display: flex;
  align-items: center;
}
.Partners-block-group {
  display: flex;
  align-items: center;
  min-height: 150px;
  max-height: 150px;
  width: 16.875rem;
  padding: 28px 25px;
  box-shadow: 0 4px 16px rgba(255, 255, 255, 0.08);
  background-color: #daecfc94;
  border-radius: 5px;
  transition: box-shadow 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.Partners-block-group:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(255, 255, 255, 0.12);
}
.Partners-block-image {
  float: left;
  width: 200px;
}
.Partners-block-title {
  font-size: 14px;
  float: left;
  margin-left: 8px;
  margin-bottom: 16px;
  color: #ffffff;
}
.Partners-block-content {
  clear: both;
  color: #ffffff;
}
@media screen and (max-width: 767px) {
  .Partners-wrapper {
    min-height: 1540px;
  }
  .Partners-wrapper.home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .Partners {
    width: 318px;
  }
  .Partners-block {
    margin: 0px;
    margin-top: 28px;
  }
}
.Recruitment-wrapper {
  height: 360px;
}
.Recruitment-wrapper .Recruitment {
  height: 100%;
  padding: 0 24px;
}
.Recruitment-wrapper .Recruitment-img {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Recruitment-wrapper .Recruitment-img span {
  display: block;
  width: 250px;
}
.Recruitment-wrapper .Recruitment-img span img {
  display: block;
}
.Recruitment-wrapper .Recruitment-text {
  padding: 0 32px;
  height: 100%;
}
.Recruitment-wrapper .Recruitment-text .Recruitment-content,
.Recruitment-wrapper .Recruitment-text .Recruitment-title {
  position: relative !important;
}
.Recruitment-wrapper .Recruitment-text .Recruitment-title {
  font-size: 32px;
  font-weight: normal;
  color: #ffffff;
  margin-top: 120px;
  text-shadow: 2px 3px 5px #0000009d;
}
.Recruitment-wrapper .Recruitment-text .Recruitment-title-a {
  color: #ffffff;
}
.Recruitment-wrapper .Recruitment-text .Recruitment-content {
  color: #ffffff;
  font-weight: bold;
}
.Recruitment-wrapper .Recruitment-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .Recruitment-wrapper {
    height: 600px;
  }
  .Recruitment-wrapper .Recruitment-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .Recruitment-wrapper .Recruitment-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .Recruitment-wrapper .Recruitment-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
    margin-top: -8rem;
  }
  .Recruitment-wrapper .Recruitment-text .Recruitment-content,
  .Recruitment-wrapper .Recruitment-text .Recruitment-title {
    width: 100%;
    top: auto;
  }
  .Recruitment-wrapper .Recruitment-text .Recruitment-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.Footer-wrapper {
  background: #001529;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: #999;
}
.Footer-wrapper .Footer .home-page {
  padding: 64px 24px 80px;
}
.Footer-wrapper .block {
  padding: 0 32px;
  width: 100%;
}
.Footer-wrapper .block .logo {
  background-image: url('http://www.xjssd.com/owres/logo2.svg');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 300px;
  min-height: 60px;
}
.Footer-wrapper .block .slogan {
  padding: 0 6px;
  font-size: 14px;
  margin-top: -20px;
  text-align: left;
}
.Footer-wrapper .block > h2 {
  margin-bottom: 24px;
  color: #ccc;
}
.Footer-wrapper .block a {
  color: #999;
  margin-bottom: 12px;
  float: left;
  clear: both;
}
.Footer-wrapper .block a:hover {
  color: #1890ff;
}
.Footer-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.Footer-wrapper .copyright-wrapper .home-page {
  padding: 0 24px;
  overflow: hidden;
}
.Footer-wrapper .copyright-wrapper .copyright {
  height: 80px;
  text-align: center;
  margin-top: 29px;
}
@media screen and (max-width: 767px) {
  .Footer {
    min-height: 350px;
  }
  .Footer-wrapper .Footer .home-page {
    padding: 64px 24px 32px;
  }
  .Footer .logo {
    margin: 0 auto 24px;
  }
  .Footer .block {
    text-align: center;
    margin-bottom: 32px;
    padding: 0;
  }
  .Footer > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .Footer > ul > li {
    width: 100%;
  }
  .Footer > ul > li h2 {
    margin-bottom: 10px;
  }
  .Footer > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .Footer .copyright-wrapper .home-page {
    padding: 0;
  }
  .Footer .copyright-wrapper .home-page .copyright {
    font-size: 12px;
    height: 100px;
    text-align: left;
    margin-left: 1rem;
  }
  .Footer .copyright span {
    width: 90%;
  }
}
#main_nav.m0q9wvlvhom-editor_css {
  position: sticky;
}
#main_recruitment.bg_rczp {
  background-image: url('http://www.xjssd.com/owres/rczp-bg.jpg');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 60%;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: padding-box;
}
#main_recruitment.bg_m_rczp {
  background-image: url('http://www.xjssd.com/owres/rczp-bg.jpg');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 50% 60%;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: padding-box;
}
#main_partners.bg_hzhb {
  background-image: url('http://www.xjssd.com/owres/ywxsjt_01.jpg');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 90%;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: padding-box;
}
#main_partners.bg_m_hzhb {
  background-image: url('http://www.xjssd.com/owres/ywxsjt_01.jpg');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 30% 90%;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: padding-box;
}
#main_infos.bg_xwzx {
  background-image: url('http://www.xjssd.com/owres/fgdn_01.jpg');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 90%;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: padding-box;
}
#main_infos.bg_m_xwzx {
  background-image: url('http://www.xjssd.com/owres/fgdn_01.jpg');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 70% 90%;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: padding-box;
}
#main_business.bg_biz {
  background-image: url('http://www.xjssd.com/owres/sljd_01.jpg');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: padding-box;
}
.bg0 {
  background: url('http://www.xjssd.com/owres/gszm_01.jpg') center;
  background-position: 0% 10%;
}
.bg0_m {
  background: url('http://www.xjssd.com/owres/gszm_01.jpg') center;
  background-position: 48% 10%;
}
.bg1 {
  background: url('http://www.xjssd.com/owres/fwqjf.jpg') center;
}
.bg1_m {
  background: url('http://www.xjssd.com/owres/fwqjf.jpg') center;
  background-position: 30% 0%;
}
.blurfilter {
  background-color: rgba(199, 199, 199, 0.15);
  border-radius: 15px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.header-logo {
  background-image: url('http://www.xjssd.com/owres/logo2.svg');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% -150%;
  background-repeat: no-repeat;
  background-size: 70%;
  width: 300px;
  min-height: 60px;
}
.ft_cp {
  color: #999;
  margin-left: 12px;
}
.ft_cp_a {
  color: #0e1835;
}

body {
  word-wrap: break-word;
  background-color: #001529;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  color: white;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.m0qfmi528hn-editor_css {
  font-size: 24px;
  color: #fbfbfb;
}
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: white;
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.I1Banner-wrapper {
  min-height: 450px;
  background-image: url('http://www.xjssd.com/owres/bg_about_01.jpg');
  background-size: cover;
  background-position: 0% 15%;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0;
}
.I1Banner-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.I1Banner-wrapper .title-content,
.I1Banner-wrapper .title-banner {
  line-height: 32px;
  color: white;
  font-weight: bolder;
  text-shadow: 2px 3px 5px #0000009d;
}
@media screen and (max-width: 767px) {
  .I1Banner-wrapper {
    background-image: url('http://www.xjssd.com/owres/bg_about_01.jpg');
    background-size: cover;
    background-position: 50% 20%;
    padding-bottom: 0;
  }
}
.I1News-wrapper {
  min-height: 720px;
  background-image: linear-gradient(to bottom, #5071b9, #3a5797, #001529);
}
.I1News-wrapper .I1News {
  padding-top: 64px;
}
.I1News-wrapper .I1News > h1,
.I1News-wrapper .I1News > p {
  text-align: center;
}
.I1News-wrapper .I1News .title-wrapper {
  font-size: 16px;
  color: white;
  text-shadow: 2px 3px 5px #0000009d;
}
.I1News-wrapper .I1News-tag {
  font-size: 24px;
  color: white;
  text-shadow: 1px 2px 3px #0000009d;
}
.I1News-wrapper .I1News-tag i {
  width: 12px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.I1News-wrapper .I1News-tag-name {
  display: inline-block;
}
.I1News-wrapper .I1News .ant-tabs-bar {
  text-align: left;
}
.I1News-wrapper .I1News .ant-tabs .ant-tabs-nav {
  float: none;
  text-align: center;
}
.I1News-wrapper .I1News-tabs-wrapper {
  position: relative;
  margin-top: 24px;
}
.I1News-wrapper .I1News-content {
  color: white;
  display: flex;
  align-items: center;
}
.I1News-wrapper .I1News-list {
  width: 100%;
  color: white;
  padding: 24px 48px;
}
.I1News-wrapper .I1News .ant-list-item-meta-title {
  font-size: 24px;
  padding: 15px 0px;
  text-shadow: 1px 2px 3px #0000009d;
}
.I1News-wrapper .I1News .ant-list-item-meta-title,
.I1News-wrapper .I1News .ant-list-item-meta-description {
  color: white;
}
.I1News-wrapper .I1News .ant-list-split .ant-list-item,
.I1News-wrapper .I1News .ant-tabs-bar,
.I1News-wrapper .I1News .ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: 1px solid #c1deff;
}
.I1News-wrapper .I1News .ant-pagination-item,
.I1News-wrapper .I1News .ant-pagination-prev .ant-pagination-item-link,
.I1News-wrapper .I1News .ant-pagination-next .ant-pagination-item-link {
  background-color: #c1deff;
}
.I1News-wrapper .I1News .ant-pagination-item-active {
  background-color: #dfedfe;
}
.I1News-wrapper .I1News-text {
  min-height: 50px;
  color: white;
}
.I1News-wrapper .I1News .ant-tabs-tabpane {
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .I1News-wrapper {
    min-height: 980px;
    overflow: hidden;
  }
  .I1News-wrapper .I1News {
    max-width: 100%;
  }
  .I1News-wrapper .I1News-content {
    display: block;
  }
  .I1News-wrapper .I1News-text,
  .I1News-wrapper .I1News-img {
    text-align: left;
    padding: 0;
  }
  .I1News-wrapper .I1News-img {
    margin-top: 32px;
  }
  .I1News-wrapper .I1News .ant-tabs-bar {
    width: auto;
  }
  .I1News-wrapper .I1News .ant-tabs-bar .ant-tabs-nav {
    float: left;
  }
}

body {
  word-wrap: break-word;
  background-color: #001529;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  color: white;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.m0qfmi528hn-editor_css {
  font-size: 24px;
  color: #fbfbfb;
}
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: white;
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.R1Banner-wrapper {
  height: 450px;
  background: url('http://www.xjssd.com/owres/rczp-bg.jpg') no-repeat bottom;
  background-size: cover;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 150px;
}
.R1Banner-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.R1Banner-wrapper .button {
  box-shadow: 0 8px 16px #0554b7;
  background: linear-gradient(to right, #05cbff, #1e5aff) !important;
  height: 42px;
  line-height: 42px;
  font-size: 14px;
  border: 0;
  border-radius: 21px;
  color: #fff;
  width: 128px;
  padding: 0 15px;
  display: inline-block;
  transition: transform 0.3s, box-shadow 0.3s;
}
.R1Banner-wrapper .button:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 20px #0554b7;
}
.R1Banner-wrapper .button:active {
  transform: translateY(4px);
  box-shadow: 0 4px 8px #0554b7;
}
.R1Banner-wrapper .title-h1 {
  text-shadow: 2px 2px 3px #0000009d;
}
.R1Banner-wrapper .title-content {
  font-size: 16px;
  line-height: 32px;
  color: white;
  text-shadow: 2px 2px 3px #0000009d;
}
@media screen and (max-width: 767px) {
  .R1Banner-wrapper {
    padding-bottom: 0;
  }
}
.R1Intro-wrapper {
  min-height: 410px;
  background: url('http://www.xjssd.com/owres/bg_xwzx_02.jpg') no-repeat bottom;
  background-size: cover;
  background-size: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0;
}
.R1Intro-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.R1Intro-wrapper .title-h1 {
  text-shadow: 2px 2px 3px #0000009d;
  padding-bottom: 10px;
}
.R1Intro-wrapper .title-content {
  font-size: 16px;
  line-height: 32px;
  color: white;
  text-shadow: 2px 2px 3px #0000009d;
}
@media screen and (max-width: 767px) {
  .R1Intro-wrapper {
    padding-bottom: 0;
  }
}
.R1Teams-wrapper {
  min-height: 446px;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #8ca8be, #4c84b8, #3a5797);
}
.R1Teams-wrapper .R1Teams {
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.R1Teams-wrapper .R1Teams > .title-wrapper {
  margin: 0 auto 48px;
}
.R1Teams-wrapper .R1Teams > .title-wrapper > h1 {
  text-shadow: 2px 2px 3px #0000009d;
}
.R1Teams-wrapper .R1Teams .block-top-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 20px 0;
}
.R1Teams-wrapper .R1Teams .block-top-wrapper .block-top {
  display: inline-block;
  text-align: center;
  margin-bottom: 48px;
}
.R1Teams-wrapper .R1Teams .block-top-wrapper .block-top.queue-anim-leaving {
  position: relative !important;
}
.R1Teams-wrapper .R1Teams-top-image,
.R1Teams-wrapper .R1Teams-top-title,
.R1Teams-wrapper .R1Teams-top-job,
.R1Teams-wrapper .R1Teams-top-content {
  width: 200px;
  margin: auto;
  line-height: 1.5;
}
.R1Teams-wrapper .R1Teams-top-image {
  color: #404040;
}
.R1Teams-wrapper .R1Teams-top-image img {
  width: 100%;
}
.R1Teams-wrapper .R1Teams-top-title {
  font-size: 24px;
  margin: 24px auto 8px;
}
.R1Teams-wrapper .R1Teams-top-job {
  margin: 8px auto;
}
.R1Teams-wrapper .R1Teams-top-job,
.R1Teams-wrapper .R1Teams-top-content {
  font-size: 12px;
  color: white;
}
.R1Teams-wrapper .R1Teams .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 20px 0;
  min-height: 456px;
  margin-top: 48px;
}
.R1Teams-wrapper .R1Teams .block-wrapper .block {
  margin-bottom: 48px;
  vertical-align: text-top;
}
.R1Teams-wrapper .R1Teams .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.R1Teams-wrapper .R1Teams-image {
  color: #404040;
  width: 100%;
}
.R1Teams-wrapper .R1Teams-image img {
  width: 100%;
}
.R1Teams-wrapper .R1Teams-textWrapper {
  padding-left: 16px;
}
.R1Teams-wrapper .R1Teams-title {
  font-size: 18px;
  margin-bottom: 2px;
}
.R1Teams-wrapper .R1Teams-job {
  margin-bottom: 4px;
}
.R1Teams-wrapper .R1Teams-job,
.R1Teams-wrapper .R1Teams-content {
  font-size: 12px;
  color: white;
}
@media screen and (max-width: 767px) {
  .R1Teams-wrapper {
    min-height: 1440px;
  }
  .R1Teams-wrapper .R1Teams.home-page {
    padding-bottom: 0;
  }
}
.R1Content-wrapper {
  min-height: 450px;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #3a5797, #253c6d, #001529);
}
.R1Content-wrapper .R1Content {
  height: 100%;
  padding: 64px 24px;
}
.R1Content-wrapper .R1Content > .title-wrapper {
  margin: 0 auto 48px;
}
.R1Content-wrapper .R1Content > .title-wrapper > h1 {
  text-shadow: 2px 2px 3px #0000009d;
}
.R1Content-wrapper .R1Content-block {
  padding: 0 1%;
  display: inline-block;
  text-align: center;
  min-height: 65px;
  margin-bottom: 24px;
}
.R1Content-wrapper .R1Content-block img {
  width: 100%;
}
.R1Content-wrapper .R1Content-block-wrapper {
  position: relative;
  height: 100%;
  top: 25%;
  padding: 20px 0;
}
.R1Content-wrapper .R1Content-block.queue-anim-leaving {
  position: relative !important;
}
.R1Content-wrapper .R1Content-block-icon {
  width: 100px;
  height: 100px;
  margin: auto;
}
.R1Content-wrapper .R1Content-block .ant-card {
  background-color: #c6daffbb;
  border: 1px solid #fffffff3;
  border-radius: 8px;
  box-shadow: 2px 2px 8px #0000008e;
  min-width: 100%;
  overflow: hidden;
}
.R1Content-wrapper .R1Content-block .ant-card-head {
  border-bottom: 1px solid #c1deff;
}
.R1Content-wrapper .R1Content-block .ant-card-head-title {
  text-align: left;
  color: black;
}
.R1Content-wrapper .R1Content-block-text {
  text-align: left;
  line-height: 28px;
  margin-bottom: 20px;
  font-size: 18px;
  color: black;
}
.R1Content-wrapper .R1Content-file:hover {
  cursor: pointer;
}
.R1Content-wrapper .R1Content-file {
  padding: 0 1%;
  display: inline-block;
  text-align: center;
  min-height: 35px;
  margin-bottom: 24px;
}
.R1Content-wrapper .R1Content-file img {
  width: 100%;
}
.R1Content-wrapper .R1Content-file-wrapper {
  position: relative;
  height: 100%;
  padding: 20px 0;
}
.R1Content-wrapper .R1Content-file.queue-anim-leaving {
  position: relative !important;
}
.R1Content-wrapper .R1Content-file-icon {
  width: 100px;
  height: 100px;
  margin: auto;
}
.R1Content-wrapper .R1Content-file .ant-card {
  background-color: #c6daffbb;
  border: 1px solid #fffffff3;
  border-radius: 4px;
  box-shadow: 2px 2px 5px #0000008e;
  min-width: 100%;
  overflow: hidden;
}
.R1Content-wrapper .R1Content-file .ant-card-head {
  min-height: 30px;
  line-height: 0;
  border-bottom: 1px solid #c1deff;
}
.R1Content-wrapper .R1Content-file .ant-card-head-title {
  text-align: center;
  color: black;
}
.R1Content-wrapper .R1Content-file-text {
  text-align: left;
  line-height: 14px;
  margin-bottom: 20px;
  font-size: 10px;
  color: black;
}
@media screen and (max-width: 767px) {
  .R1Content-wrapper {
    min-height: 450px;
  }
}

body {
  word-wrap: break-word;
  background-color: #001529;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  color: white;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.m0qfmi528hn-editor_css {
  font-size: 24px;
  color: #fbfbfb;
}
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: white;
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.B1Banner-wrapper {
  min-height: 450px;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0;
}
.B1Banner-wrapper.home-page-wrapper .title-wrapper {
  padding-left: 15%;
  padding-top: 110px;
  margin-bottom: 32px;
  color: #c1deff;
  text-align: left;
  text-shadow: 2px 3px 5px #0000009d;
}
.B1Banner-wrapper.home-page-wrapper .title-h1 {
  color: #c1deff;
}
.B1Banner-wrapper .title-content {
  line-height: 32px;
}
@media screen and (max-width: 767px) {
  .B1Banner-wrapper {
    padding-bottom: 0;
  }
}
.B1Video-wrapper {
  min-height: 720px;
}
.B1Video-wrapper .B1Video {
  height: 100%;
  overflow: hidden;
}
.B1Video-wrapper .B1Video-video {
  border-radius: 4px;
  overflow: hidden;
  max-width: 800px;
  margin: auto;
  background: #fff;
  box-shadow: 5px 5px 16px rgba(0, 0, 0, 0.75);
}
.B1Video-wrapper .B1Video-video video {
  display: block;
  margin: auto;
}
.B1Video-wrapper .B1Video .title-h1,
.B1Video-wrapper .B1Video .title-wrapper {
  color: #fff;
  text-shadow: 2px 3px 5px #0000009d;
}
@media screen and (max-width: 767px) {
  .B1Video-wrapper {
    min-height: 350px;
  }
  .B1Video-wrapper .B1Video {
    overflow: hidden;
    width: 90%;
    margin: auto;
  }
  .B1Video-wrapper .B1Video-video {
    top: 15%;
    background: url("https://zos.alipayobjects.com/rmsportal/HZgzhugQZkqUwBVeNyfz.jpg") no-repeat center;
    background-size: cover;
  }
}
.B1Data1 .B1Data1-content-wrapper {
  padding: 30px 0px;
}
.B1Data1-wrapper {
  min-height: 360px;
  margin: 0 auto;
  overflow: hidden;
}
.B1Data1-wrapper.home-page-wrapper .home-page {
  padding: 64px 24px;
}
.B1Data1 .B1Data1-wrapperm {
  min-height: 180px;
  max-height: 200px;
  margin: 0 auto;
  overflow: hidden;
}
.B1Data1 .B1Data1-wrapperm.home-page-wrapper .home-page {
  padding: 64px 24px;
}
.B1Data1-title {
  display: inline-block;
}
.B1Data1-title-wrapper {
  text-align: center;
  margin-bottom: 48px;
}
.B1Data1-title-text {
  display: inline-block;
  margin: 0 72px;
  font-size: 24px;
  color: #c1deff;
  transition: color 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
}
.B1Data1-title-text.active {
  color: #fff;
  text-shadow: 2px 3px 5px #0000009d;
}
.B1Data1-title-bar {
  height: 6px;
  background: #fff;
  width: 20%;
  margin: auto;
  display: block;
  box-shadow: 0px 1px 6px #00000069;
}
.B1Data1-title-bar-wrapper {
  position: relative;
  margin-top: 8px;
  transition: left 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.B1Data1-number,
.B1Data1-text {
  text-align: center;
  color: #c1deff;
}
.B1Data1-number {
  font-size: 48px;
  color: #fff;
  font-weight: 600;
  text-shadow: 2px 3px 5px #0000009d;
}
.B1Data1-number-wrapper {
  text-align: center;
}
.B1Data1-text {
  font-size: 20px;
  text-shadow: 2px 3px 5px #0000009d;
}
.B1Data1-unit {
  font-size: 16px;
  color: #fff;
  text-shadow: 2px 3px 5px #0000009d;
}
.B1Data1 .ant-carousel .slick-dots-bottom {
  bottom: -24px;
}
.B1Data1 .ant-carousel .slick-dots li button {
  background: rgba(24, 144, 255, 0.3);
}
.B1Data1 .ant-carousel .slick-dots li.slick-active button {
  background: #1890ff;
}
@media screen and (max-width: 767px) {
  .B1Data1-wrapper {
    padding-bottom: 0;
    min-height: 580px;
  }
  .B1Data1-wrapper.home-page-wrapper .home-page {
    padding: 56px 24px 64px;
  }
  .B1Data1-title-text {
    margin: 0 14px;
  }
  .B1Data1-title-bar {
    width: 40%;
  }
  .B1Data1-number-wrapper {
    margin-bottom: 16px;
  }
}
.B1Intro-wrapper {
  min-height: 720px;
}
.B1Intro-wrapper .B1Intro {
  height: 100%;
  display: flex;
  align-items: center;
}
.B1Intro-wrapper .B1Intro-text {
  min-height: 424px;
}
.B1Intro-wrapper .B1Intro-text > *.queue-anim-leaving {
  position: relative !important;
}
.B1Intro-wrapper .B1Intro-text .title-h1 {
  position: relative;
  margin: 0 0 16px;
  text-align: left;
  font-size: 2em;
  color: #fff;
  text-shadow: 2px 3px 5px #0000009d;
}
.B1Intro-wrapper .B1Intro-text .title-content {
  position: relative;
  margin-bottom: 64px;
  text-align: left;
  color: #fff;
}
.B1Intro-wrapper .B1Intro-text ul {
  position: relative;
  display: inline-block;
  color: #fff;
}
.B1Intro-wrapper .B1Intro-text ul li {
  margin-bottom: 24px;
}
.B1Intro-wrapper .B1Intro-text ul li .B1Intro-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
}
.B1Intro-wrapper .B1Intro-text ul li .B1Intro-title,
.B1Intro-wrapper .B1Intro-text ul li .B1Intro-content {
  margin-left: 5px;
  color: #fff;
  max-width: 90%;
}
.B1Intro-wrapper .B1Intro-text ul li .B1Intro-title {
  font-size: 14px;
  text-shadow: 1px 1px 3px #0000009d;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .B1Intro-wrapper {
    height: 860px;
    overflow: hidden;
  }
  .B1Intro-wrapper .B1Intro {
    display: block;
    left: 2rem;
  }
  .B1Intro-wrapper .B1Intro-img,
  .B1Intro-wrapper .B1Intro-text {
    display: block;
    width: 100%;
  }
  .B1Intro-wrapper .B1Intro-text > h1,
  .B1Intro-wrapper .B1Intro-text > p {
    text-align: center;
  }
  .B1Intro-wrapper .B1Intro-text > h1 {
    margin: 56px auto 16px;
  }
  .B1Intro-wrapper .B1Intro-text p {
    margin-bottom: 32px;
  }
  .B1Intro-wrapper .B1Intro-img {
    margin-top: 20px;
  }
}
.B1Partners-wrapper {
  min-height: 470px;
}
.B1Partners-wrapper .B1Partners {
  padding: 64px 24px;
}
.B1Partners-wrapper .B1Partners .title-h1 {
  color: white;
  text-shadow: 2px 3px 5px #0000009d;
}
.B1Partners-wrapper .B1Partners > p {
  text-align: center;
}
.B1Partners-wrapper .img-wrapper {
  margin: 0 auto;
  left: 0;
  right: 0;
  height: 330px;
}
.B1Partners-wrapper .img-wrapper .block {
  margin-bottom: 40px;
}
.B1Partners-wrapper .img-wrapper .block .block-content {
  display: flex;
  border-radius: 4px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border: none;
  height: 164px;
  align-items: center;
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.B1Partners-wrapper .img-wrapper .block .block-content > span {
  width: 100%;
  display: block;
}
.B1Partners-wrapper .img-wrapper .block .block-content .imgwp {
  display: flex;
  align-items: center;
  margin: auto;
  width: 250px;
  height: 150px;
  padding: 15px 25px;
  border-radius: 5px;
  background-color: #ffffff80;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  vertical-align: middle;
}
.B1Partners-wrapper .img-wrapper .block .block-content img {
  width: 200px;
}
@media screen and (max-width: 767px) {
  .B1Partners-wrapper {
    overflow: hidden;
  }
  .B1Partners-wrapper .B1Partners ul li {
    display: block;
    width: 100%;
    padding: 2%;
  }
  .B1Partners-wrapper .B1Partners ul li span {
    height: 168px;
  }
}
.blurfilter {
  background-color: rgba(199, 199, 199, 0.15);
  border-radius: 15px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.bg2 {
  background: url('http://www.xjssd.com/owres/bizbg_01.jpg') no-repeat;
  background-size: cover;
  background-position: 0% 50%;
}
.bg2_m {
  background: url('http://www.xjssd.com/owres/bizbg_01.jpg') no-repeat;
  background-size: cover;
  background-position: 25% 50%;
}
.bg3 {
  background: url('http://www.xjssd.com/owres/xjjsjqeqsjt.jpg') no-repeat bottom;
  background-size: cover;
  background-position: 0% 18%;
}
.bg3_m {
  background: url('http://www.xjssd.com/owres/xjjsjqeqsjt.jpg') no-repeat bottom;
  background-size: 225%;
  background-position: 50% 0%;
}
.bg4 {
  background: url('http://www.xjssd.com/owres/fwqjf_02.jpg') no-repeat;
  background-size: cover;
  background-position: 20% 40%;
}
.bg4_m {
  background: url('http://www.xjssd.com/owres/fwqjf_02.jpg') no-repeat;
  background-size: cover;
  background-position: 20% 40%;
}
.bg5 {
  background: url('http://www.xjssd.com/owres/sljd_02.jpg') no-repeat;
  background-size: cover;
  background-position: 0% 60%;
  padding-top: 1.25rem;
}
.bg5_m {
  background: url('http://www.xjssd.com/owres/sljd_02.jpg') no-repeat;
  background-size: cover;
  background-position: 28% 0%;
  padding-top: 6.25rem;
}
.B1B2_bg {
  min-height: 1200px;
  background: url('http://www.xjssd.com/owres/sljjcyqgh.jpg') no-repeat;
  background-size: cover;
  background-position: 40% 0%;
}
.B1B2_bg_m {
  min-height: 225px;
  background: url('http://www.xjssd.com/owres/sljjcyqgh.jpg') no-repeat;
  background-size: 100%;
  background-position: 70% 0%;
}
#B1Partners {
  background-image: linear-gradient(to bottom, #83baec, #4163ac, #001529);
}

body {
  word-wrap: break-word;
  background-color: #001529;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  color: white;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.m0qfmi528hn-editor_css {
  font-size: 24px;
  color: #fbfbfb;
}
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: white;
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.A1Banner-wrapper {
  height: 450px;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 128px;
}
.A1Banner-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.A1Banner-wrapper.home-page-wrapper .title-h1 {
  text-shadow: 2px 2px 3px #0000009d;
}
.A1Banner-wrapper .button {
  box-shadow: 0 8px 16px #0554b7;
  background: linear-gradient(to right, #05cbff, #1e5aff) !important;
  height: 42px;
  line-height: 42px;
  font-size: 14px;
  border: 0;
  border-radius: 21px;
  color: #fff;
  width: 128px;
  padding: 0 15px;
  display: inline-block;
  transition: transform 0.3s, box-shadow 0.3s;
}
.A1Banner-wrapper .button:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 20px #0554b7;
}
.A1Banner-wrapper .button:active {
  transform: translateY(4px);
  box-shadow: 0 4px 8px #0554b7;
}
.A1Banner-wrapper .title-content {
  line-height: 32px;
  color: white;
}
@media screen and (max-width: 767px) {
  .A1Banner-wrapper {
    padding-bottom: 0;
  }
}
.A1Intro-wrapper {
  min-height: 410px;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0;
}
.A1Intro-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.A1Intro-wrapper .title-h1 {
  text-shadow: 2px 2px 3px #0000009d;
  padding-bottom: 10px;
}
.A1Intro-wrapper .title-content {
  font-size: 16px;
  line-height: 32px;
  color: white;
  text-shadow: 2px 2px 3px #0000009d;
}
@media screen and (max-width: 767px) {
  .A1Intro-wrapper {
    padding-bottom: 0;
  }
}
.A1History-wrapper {
  padding-bottom: 64px;
  background-image: linear-gradient(to bottom, #5071b9, #3a5797, #001529);
}
.A1History-wrapper.home-page-wrapper {
  overflow: initial;
}
.A1History-wrapper .A1History {
  min-height: 400px;
  padding: 64px 0 0;
}
.A1History-wrapper .A1History .title-h1 {
  text-shadow: 2px 2px 3px #0000009d;
}
.A1History-wrapper .timeline {
  position: relative;
  padding-top: 50px;
}
.A1History-wrapper .timeline:before {
  display: block;
  content: '';
  position: absolute;
  left: 30%;
  top: 50px;
  width: 0;
  height: calc(100% - 108px);
  margin: 34px 0;
  border-left: 2px #ebedf0 dashed;
  overflow: hidden;
}
.A1History-wrapper .block-wrapper {
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  position: relative;
  margin-bottom: 70px;
  min-height: 160px;
  left: 30%;
}
.A1History-wrapper .block-wrapper:last-child {
  margin-bottom: 0;
}
.A1History-wrapper .image-wrapper,
.A1History-wrapper .text-wrapper {
  width: 50%;
}
.A1History-wrapper .image-wrapper {
  text-align: center;
}
.A1History-wrapper .image-wrapper .block-img,
.A1History-wrapper .image-wrapper .name-wrapper {
  float: right;
  clear: both;
  width: 262px;
  margin: auto;
}
.A1History-wrapper .image-wrapper .block-img {
  height: 98px;
}
.A1History-wrapper .image-wrapper .block-img img {
  height: 100%;
}
.A1History-wrapper .image-wrapper .name-wrapper {
  margin: 16px 0 0;
}
.A1History-wrapper .image-wrapper .name-wrapper .block-name {
  font-size: 14px;
  margin-bottom: 4px;
}
.A1History-wrapper .image-wrapper .name-wrapper .block-post {
  font-size: 12px;
  color: #697b8c;
}
.A1History-wrapper .text-wrapper {
  padding-left: 40px;
}
.A1History-wrapper .text-wrapper .block-time {
  font-size: 14px;
  line-height: 18px;
  min-height: 18px;
  color: rgba(255, 255, 255, 0.822);
}
.A1History-wrapper .text-wrapper .block-title {
  margin: 8px 0 12px;
  font-size: 20px;
  position: relative;
  min-height: 18px;
  color: white;
}
.A1History-wrapper .text-wrapper .block-icon {
  position: absolute;
  left: -40px;
  transform: translateX(calc(-50% + 1px));
}
.A1History-wrapper .text-wrapper .block-content {
  width: 300px;
  color: white;
  font-size: 16px;
  min-height: 18px;
}
@media screen and (max-width: 767px) {
  .A1History-wrapper {
    padding-bottom: 0;
  }
  .A1History-wrapper .A1History {
    padding: 64px 24px;
  }
  .A1History-wrapper .timeline:before {
    left: 0;
    height: 100%;
    margin: 0;
  }
  .A1History-wrapper .block-wrapper {
    display: block;
    min-height: 100px;
    margin-bottom: 54px;
    left: 0%;
  }
  .A1History-wrapper .image-wrapper,
  .A1History-wrapper .text-wrapper {
    width: 100%;
  }
  .A1History-wrapper .image-wrapper .block-img {
    display: none;
  }
  .A1History-wrapper .image-wrapper .name-wrapper {
    text-align: left;
    float: left;
    width: 100%;
    padding-left: 40px;
    margin: auto;
  }
  .A1History-wrapper .image-wrapper .block-name,
  .A1History-wrapper .image-wrapper .block-post {
    display: inline-block;
  }
  .A1History-wrapper .image-wrapper .block-name {
    margin-right: 8px;
  }
  .A1History-wrapper .text-wrapper .block-time {
    font-size: 12px;
  }
  .A1History-wrapper .text-wrapper .block-content {
    font-size: 16px;
  }
  .A1History-wrapper .text-wrapper .block-title {
    font-size: 18px;
  }
}
#A1History.m1etn3fayle-editor_css {
  background-clip: padding-box;
  background-color: #1f2d3f;
}
.bg6 {
  background: url('http://www.xjssd.com/owres/bg_xwzx_01.jpg') no-repeat bottom;
  background-size: cover;
  background-position: 0% 80%;
}
.bg6_m {
  background: url('http://www.xjssd.com/owres/bg_xwzx_01.jpg') no-repeat bottom;
  background-size: cover;
  background-position: 40% 0%;
}
.bg7 {
  background: url('http://www.xjssd.com/owres/bg_xwzx_02.jpg') no-repeat bottom;
  background-size: cover;
  background-position: 0% 80%;
}
.bg7_m {
  background: url('http://www.xjssd.com/owres/bg_xwzx_02.jpg') no-repeat bottom;
  background-size: cover;
  background-position: 40% 0%;
}

